import React from 'react';
import '../assets/styles/Impressum.css';

const Impressum = () => {
  return (
    <div className="impressum-container">
      <h1>Ihr Impressum</h1>
    <p>
        Im Folgenden finden Sie die Textdaten für Ihr persönliches Website-Impressum gemäß der von Ihnen getätigten Angaben. 
        Sofern Sie in Ihrer Website einen Eingabe-Editor für Ihre Inhalte nutzen, können Sie den folgenden Text direkt herauskopieren und dort einfügen.
    </p>
    <p>
        Sofern Sie die Inhalte alternativ als HTML-Quellcode in Ihrem Website integrieren möchten, können Sie den anschließend aufgeführten HTML-Quellcode nutzen.
    </p>

    <div className="impressum-section">
    <h2>Textversion des Impressums für Ihre Website</h2>
</div>
      <div className="impressum-section">
        <h2>Impressum</h2>
        <p>Qendrim Simnica<br />
        Anlagenmechaniker SHK<br />
        August-Lämmle-Str.21<br />
        Gebäude 44<br />
        72766 Reutlingen</p>
      </div>

      <div className="impressum-section">
        <h2>Kontakt</h2>
        <p>Telefon: +491749574387<br />
        E-Mail: simnica-shk@web.de</p>
      </div>

      <div className="impressum-section">
        <h2>Umsatzsteuer-ID</h2>
        <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br />
        78442/16205</p>
      </div>

      <div className="impressum-section">
        <h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
        <p>Berufsbezeichnung:<br />
        Installateur und Heizungsbaumeister</p>

        <p>Zuständige Kammer:<br />
        Handwerkskammer<br />
        Hindenburgstraße 58<br />
        72762 Reutlingen</p>

        <p>Verliehen in:<br />
        Deutschland</p>
      </div>

      <div className="impressum-section">
        <h2>Angaben zur Berufshaftpflichtversicherung</h2>
        <p><strong>Name und Sitz des Versicherers:</strong><br />
        Gothaer<br />
        Odeonsplatz 18<br />
        80539 München</p>

        <p><strong>Geltungsraum der Versicherung:</strong><br />
        Deutschland</p>
      </div>

      {/* <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p> */}
    </div>
  );
};

export default Impressum;
