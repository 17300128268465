import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/Header.css';

const Header = ({ title }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to track if the menu is open
  const navigate = useNavigate();

  // Close the menu when navigating to a new page
  const handleNavigation = (path) => {
    setIsMenuOpen(false); // Close the menu
    navigate(path); // Navigate to the specified path
  };

  // Toggle the menu open/close state
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  return (
    <header className="header_section">
      <div className="header_top">
        <div className="container-fluid">
          <div className="contact_nav">
            <a href="/" aria-label="Call us">
              <span>FIRMA SIMNICA</span>
            </a>
            <a href="mailto:simnica-shk@web.de" aria-label="Email us">
              <i className="fa fa-envelope" aria-hidden="true"></i>
              <span>simnica-shk@web.de</span>
            </a>
          </div>
        </div>
      </div>
      <div className="header_bottom">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg custom_nav-container">
            <button className="navbar-brand" onClick={() => handleNavigation('/weißhaupt')}>
              <span>{title}</span>
            </button>

            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMenu}
              aria-expanded={isMenuOpen ? 'true' : 'false'}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <div></div>
              </span>
            </button>

            {/* Adjust this div based on menu state */}
            <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <button className="nav-link" onClick={() => handleNavigation('/weißhaupt')}>
                    Home
                  </button>
                </li>
                <li className="nav-item">
                  <button className="nav-link" onClick={() => handleNavigation('/notdienst')}>
                    Über uns
                  </button>
                </li>
                <li className="nav-item">
                  <button className="nav-link" onClick={() => handleNavigation('/bäder')}>
                    Dienstleistungen
                  </button>
                </li>
                <li className="nav-item">
                  <button className="nav-link" onClick={() => handleNavigation('/pfullingen')}>
                    Kontakt
                  </button>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
