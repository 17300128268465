import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/Footer.css';

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    window.scrollTo(0, 0); // Scrolls to top
    navigate(path); // Navigates to the route
  };

  return (
    <footer className="footer_section">
      <section className="info_section">
        <div className="container">
          <h4>Get In Touch</h4>
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="info_items">
                <div className="row justify-content-center text-center">
                  <div className="col-md-2">
                    <a href="https://www.google.com/maps/place/August-L%C3%A4mmle-Stra%C3%9Fe+21,+72766+Reutlingen,+Germany">
                      <div className="item">
                        <div className="img-box">
                          <i className="fa fa-map-marker" aria-hidden="true"></i>
                        </div>
                        <p>August-Lämmle-Straße 21, 72766 Reutlingen</p>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-2">
                    <a href="tel:+4915754652558">
                      <div className="item">
                        <div className="img-box">
                          <i className="fa fa-phone" aria-hidden="true"></i>
                        </div>
                        <p>+49 15754652558</p>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-2">
                    <a href="mailto:simnica-shk@web.de">
                      <div className="item">
                        <div className="img-box">
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                        </div>
                        <p>simnica-shk@web.de</p>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-2">
                    <a href="https://www.instagram.com/simnica.shk/">
                      <div className="item">
                        <div className="img-box">
                          <i className="fa fa-instagram" aria-hidden="true"></i>
                        </div>
                        <p>Instagram</p>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-2">
                    <a href="https://wa.me/4915754652558" target="_blank" rel="noopener noreferrer">
                      <div className="item">
                        <div className="img-box">
                          <i className="fa fa-whatsapp" aria-hidden="true"></i>
                        </div>
                        <p>WhatsApp</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <p>
            &copy; <span id="displayDateYear"></span> All Rights Reserved |
            <span className="footer-link" onClick={() => handleNavigation("/impressum")}> Impressum </span> |
            <span className="footer-link" onClick={() => handleNavigation("/nutzungsbedingungen")}> Nutzungsbedingungen </span>
          </p>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
