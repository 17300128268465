import { useEffect, useState } from "react";
import "../assets/styles/CookieBanner.css";

const CookieModal = () => {
  const [visible, setVisible] = useState(false);
  const [viewingPolicy, setViewingPolicy] = useState(false);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem("cookiesAccepted");
    if (!hasAcceptedCookies) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookiesAccepted", "false");
    setVisible(false);
  };

  const handlePrivacyClick = (e) => {
    e.preventDefault();
    setViewingPolicy(true);
    setVisible(false);
    window.location.href = "/nutzungsbedingungen"; // Navigate to the privacy policy page
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && viewingPolicy) {
        setViewingPolicy(false);
        setVisible(true);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, [viewingPolicy]);

  if (!visible) return null;

  return (
    <div className="cookie-modal-overlay">
      <div className="cookie-modal">
        <button className="close-btn" onClick={handleAccept}>×</button>
        <h2>Wir nutzen <span className="highlight">cookies</span></h2>
        <p>Weishaupt verwendet nur technisch notwendige Cookies auf dieser Website.</p>
        <div className="button-group">
          <button className="agree-btn" onClick={handleAccept}>Akzeptieren <span>&#x25B6;</span></button>
          <button className="decline-btn" onClick={handleDecline}>Ablehnen</button>
        </div>
        <p className="privacy-text">
          Mehr Informationen zur Nutzung von Benutzerdaten finden Sie in unserer{" "}
          <a href="/nutzungsbedingungen" onClick={handlePrivacyClick}>Datenschutzerklärung</a>.
        </p>
      </div>
    </div>
  );
};

export default CookieModal;
